import {baseEvent} from './baseEvent';
import {EVENTS} from '../events';

/**
 * @param {string} errorMessage
 * @param {object|null} metadata
 * @returns {import('./baseEvent').AmplitudeEvent}
 */
export function errorOccurred(errorMessage, metadata = null) {
	return baseEvent(EVENTS.ERROR_OCCURRED, {
		errorMessage,
		metadata,
	});
}
