import {useEffect, useRef} from 'react';

/**
 * callback - callback function, pass dependencies array as param
 * dependencies - array of dependencies
 * condition - function that takes a dependency array, returns true / false
 */
export const useEffectOnlyOnce = (callback, dependencies = [], condition = c => Boolean(c)) => {
	const calledOnce = useRef(false);

	useEffect(() => {
		if (calledOnce.current) {
			return;
		}

		if (condition(dependencies)) {
			callback(dependencies);

			calledOnce.current = true;
		}
	}, [callback, condition, dependencies]);
};

/* USAGE EXAPLE */
/*
const App = () => {
  const [toggle, setToggle] = React.useState(true);

  const handleToggle = () => setToggle(!toggle)};

	//runs once when dependencies pass the condition
  useEffectOnlyOnce(
    (dependencies) => {
      console.log('I run only once if toggle is false.');
    },
    [toggle],
    (dependencies) => dependencies[0] === false
  );

	//runs once when component did mount
	useEffectOnlyOnce(
    (dependencies) => {
      console.log('I run only once if toggle is false.');
    }, []);

  return (
    <div>
      <button type="button" onClick={handleToggle}>Toggle</button>
      {toggle && <div>Hello React</div>}
    </div>
  );
};
*/
