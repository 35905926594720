import * as amplitude from '@amplitude/analytics-browser';
import {EVENTS} from './events';

// Amplitude SDK mock used on 'test' environment. It's required
// because an error is occured from parts of code where Tracker is used.
// Error is thrown even Tracker is mocked - probably there's a problem
// with mocking external library as '@amplitude/analytics-browser'.
const amplitudeTestSdk = {
	init() {},
	track() {},
	setUserId() {},
	setUserProperties() {},
	identify() {},
	setTransport() {},
	flush() {},
	Types: {
		ServerZone: {
			EU: 'EU',
		},
	},
};

let trackerInstance = null;

export class Tracker {
	/**
	 * @param {import('@amplitude/analytics-browser')} amplitudeInstance
	 * @param {{log: function(...any): void}?} logger
	 */
	constructor(amplitudeInstance, logger = console) {
		this.amplitudeInstance = amplitudeInstance;
		this.logger = logger;
		this.isTrackingEnabled =
			process.env.NODE_ENV === 'production' &&
			!(window.__Cypress__ || window.Cypress) &&
			process.env.REACT_APP_AMPLITUDE_API_KEY;

		if (this.isTrackingEnabled) {
			this.amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
				serverZone: this.amplitudeInstance.Types.ServerZone.EU,
				trackingOptions: {
					ip_address: false, // eslint-disable-line camelcase
				},
			});
		}

		window.addEventListener('pagehide', () => {
			if (
				!this.isTrackingEnabled ||
				process.env.NODE_ENV === 'test' ||
				!process.env.REACT_APP_AMPLITUDE_API_KEY
			) {
				return;
			}

			amplitude.setTransport('beacon');
			amplitude.track(EVENTS.PAGE_EXITED, {});
			amplitude.flush();
		});
	}

	/**
	 * @param {Function} callback
	 */
	call(callback) {
		if (!this.isTrackingEnabled) {
			return;
		}

		callback();
	}

	/**
	 * @param {string} name
	 * @param {object|null} payload
	 */
	track(name, payload = null) {
		if (process.env.NODE_ENV === 'test' || !process.env.REACT_APP_AMPLITUDE_API_KEY) {
			return;
		}

		if (!this.isTrackingEnabled) {
			this.logger.log(`Event: ${name}. Payload: ${JSON.stringify(payload, null, 2)}`);
			return;
		}

		this.amplitudeInstance.track(name, payload);
	}

	/**
	 * @param {string} id
	 */
	setUserId(id) {
		this.call(() => this.amplitudeInstance.setUserId(id));
	}

	/**
	 * @param {import('@amplitude/analytics-browser').Identify} identify
	 */
	identify(identify) {
		this.call(() => this.amplitudeInstance.identify(identify));
	}

	/**
	 * @returns {Tracker}
	 */
	static getInstance() {
		const amplitudeInstance =
			process.env.NODE_ENV === 'test' || !process.env.REACT_APP_AMPLITUDE_API_KEY ? amplitudeTestSdk : amplitude;

		if (!trackerInstance) {
			trackerInstance = new Tracker(amplitudeInstance);
		}

		return trackerInstance;
	}
}
