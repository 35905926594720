import {useTranslation} from 'react-i18next';
import Modal from './Modal';
import smartphoneError from '../assets/images/smartphoneError.svg';

function Error() {
	const {t} = useTranslation();

	return (
		<Modal>
			<div className="flex flex-auto items-center justify-center p-5 w-full bg-white">
				<div className="text-center">
					<div className="inline-flex rounded-full bg-red-100 p-4">
						<div className="rounded-full stroke-red-600 bg-red-200 p-4">
							<img src={smartphoneError} className="w-36 p-4" />
						</div>
					</div>
					<h1 className="mt-5 text-[36px] font-bold text-slate-800 lg:text-[50px]">{t('Error-title')}</h1>
					<p className="text-slate-600 mt-2 lg:text-lg">{t('Error-content')}</p>
				</div>
			</div>
		</Modal>
	);
}

export default Error;
