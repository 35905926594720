import {useEffect, useMemo, useRef, useState} from 'react';
import QrScanner from 'qr-scanner';
import Error from './Error';
import {useDomain, useEffectOnlyOnce} from '../hooks';
import urlSvg from '../assets/images/url.svg';
import chevronSvg from '../assets/images/chevron.svg';
import {track} from '../services/amplitude/functions';
import {errorOccurred, userEnteredScanner} from '../services/amplitude/models';

function Scanner() {
	const videoRef = useRef(null);
	const [scanUrl, setScanUrl] = useState('');
	const [corners, setCorners] = useState(null);
	const [isError, setIsError] = useState(false);
	const scanner = useRef(null);
	const domain = useDomain(scanUrl);

	useEffectOnlyOnce(() => {
		track(userEnteredScanner());
	}, []);

	useEffect(() => {
		scanner.current = new QrScanner(
			document.getElementById('video'),
			result => {
				if (result.data.includes('https')) {
					setScanUrl(result.data);
					setCorners(result.cornerPoints);
				}
			},
			{
				preferredCamera: 'environment',
				highlightScanRegion: true,
				maxScansPerSecond: 1,
				returnDetailedScanResult: true,
			},
		);

		scanner.current.start().catch(error => {
			if (error === 'Camera not found.') {
				track(errorOccurred("Permission denied or camera hasn't been found"));
				console.error("Permission denied or camera hasn't been found:", error);
				setIsError(true);
			}
		});
	}, []);

	const qrCodeBottomPosition = useMemo(() => {
		if (corners) {
			const regionY = corners[3].y || 0;
			const top = (regionY / videoRef.current.videoHeight) * videoRef.current.offsetHeight;

			return top;
		}

		return 0;
	}, [corners, videoRef]);

	return (
		<div className="relative w-full h-full">
			{isError && <Error />}
			<video
				ref={videoRef}
				playsInline
				muted
				autoPlay
				data-testid="video"
				id="video"
				className="relative object-contain max-h-full mx-auto"
			/>
			{domain && (
				<div
					data-testid="ScanResult"
					className="absolute w-full flex flex-col items-center justify-center"
					style={{top: qrCodeBottomPosition}}
				>
					<a
						data-testid="ScanResult-redirectUrl"
						href={scanUrl}
						className="flex flex-row px-4 py-2 items-center rounded-full text-center mt-8 cursor-pointer bg-amber-300 text-black"
					>
						<img src={urlSvg} className="w-4 mr-2" />
						<span data-testid="ScanUrl-redirectDomain">{domain}</span>
						<img src={chevronSvg} className="h-6 ml-2" />
					</a>
				</div>
			)}
		</div>
	);
}

export default Scanner;
