import {baseEvent} from './baseEvent';
import {EVENTS} from '../events';

/**
 * @param {string} domain
 * @returns {import('./baseEvent').AmplitudeEvent}
 */
export function domainHasBeenRead(domain) {
	return baseEvent(EVENTS.DOMAIN_HAS_BEEN_READ, {
		domain,
	});
}
