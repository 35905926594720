import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import qrScanner from '../assets/images/qrScanner.svg';
import chevron from '../assets/images/chevron.svg';
import {track} from '../services/amplitude/functions';
import {userEnteredOnboarding} from '../services/amplitude/models';
import {useEffectOnlyOnce} from '../hooks/useEffectOnlyOnce';

function Onboarding({onNext}) {
	const {t} = useTranslation();

	useEffectOnlyOnce(() => {
		track(userEnteredOnboarding());
	}, []);

	return (
		<div data-testid="Onboardig-cntr" className="flex flex-col items-center justify-between p-5 w-full bg-white">
			<div className="flex flex-col flex-auto items-center justify-center">
				<div className="inline-flex rounded-full bg-blue-100 p-2">
					<div className="rounded-full stroke-blue-600 bg-blue-200 p-4">
						<img src={qrScanner} className="w-52 sm:w-96" />
					</div>
				</div>
				<h1 className="mt-5 text-[36px] font-bold text-slate-800 lg:text-[50px]">{t('Onboarding-title')}</h1>
				<p className="text-slate-600 mt-2 lg:text-lg text-center">{t('Onboarding-content')}</p>
			</div>
			<button
				type="button"
				data-testid="Onboardig-nextButton"
				className="flex flex-row pl-4 pr-2 py-2 items-center rounded-full text-center mt-8 cursor-pointer bg-blue-300 text-slate-800 font-medium"
				onClick={onNext}
			>
				<span>{t('Start')}</span>
				<img src={chevron} className="h-6 ml-2" />
			</button>
		</div>
	);
}

Onboarding.propTypes = {
	onNext: PropTypes.func.isRequired,
};

export default Onboarding;
