/**
 * @typedef {{name: string, payload: object}} AmplitudeEvent
 * @param {string} name
 * @param {object?} payload
 * @returns {AmplitudeEvent}
 */
export function baseEvent(name, payload = {}) {
	return {
		name,
		payload,
	};
}
