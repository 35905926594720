import PropTypes from 'prop-types';

function Modal({children}) {
	return (
		<div
			data-testid="Modal"
			className="absolute z-10 w-screen h-screen inset-0 bg-black/70 flex flex-col items-center justify-center"
		>
			{children}
		</div>
	);
}

Modal.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Modal;
