import {Tracker} from '../Tracker';

/**
 * @param {import("../../models/amplitude").AmplitudeEvent} event
 * @param {Function?} onEventSent
 */
export function track(event, onEventSent = () => {}) {
	const tracker = Tracker.getInstance();

	tracker.track(event.name, event.payload);

	onEventSent();
}
