import {useMemo} from 'react';
import {track} from '../services/amplitude/functions';
import {domainHasBeenRead, errorOccurred} from '../services/amplitude/models';

/**
 * @param {string} url
 * @returns {{
 * 	domain: string,
 * }}
 */
export const useDomain = url => {
	const domain = useMemo(() => {
		try {
			const regex = /^(https:\/\/)\/?([\w\-.]+)?(.*)?$/;
			const urlParts = regex.exec(url);

			if (!urlParts) {
				return '';
			}

			const domainResult = urlParts[2].split('.').slice(-2).join('.');
			track(domainHasBeenRead(domainResult));
			return domainResult;
		} catch (error) {
			track(errorOccurred(error));
			console.error(error);
			return url;
		}
	}, [url]);

	if (!url) {
		return '';
	}

	return domain;
};
