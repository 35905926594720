import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en from './locales/en';
import pl from './locales/pl';

const locales = {en, pl};

const i18nOptions = {
	resources: Object.entries(locales).reduce(
		(acc, [language, translation]) => ({
			...acc,
			[language]: {translation},
		}),
		{},
	),
	supportedLngs: Object.keys(locales),
	fallbackLng: 'en',
	debug: true,
	interpolation: {
		escapeValue: false,
	},
	react: {
		wait: true,
		omitBoundRerender: false,
	},
	detection: {
		lookupQuerystring: 'lang',
		order: ['querystring', 'navigator'],
	},
};

i18n.use(initReactI18next).use(LanguageDetector).init(i18nOptions); // eslint-disable-line

export default i18n;
