import {useState} from 'react';
import Onboarding from './Onboarding';
import Scanner from './Scanner';

function App() {
	const [isOnboarding, setIsOnboarding] = useState(true);

	if (isOnboarding) {
		return <Onboarding onNext={() => setIsOnboarding(false)} />;
	}

	return <Scanner />;
}

export default App;
